import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControl,
  FormControlLabel,
  FormGroup,
  Checkbox,
  IconButton
} from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import styles from './styles'

const Step1View = ({ classes, values, onChange, updateView }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between'
      }}
    >
      <FormControl className={classes.radioGroup}>
        <FormGroup className={classes.radioColumn}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.sellTickets}
                onChange={onChange('sellTickets')}
                value={'sellTickets'}
              />
            }
            label="Sell Tickets"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.checkIn}
                onChange={onChange('checkIn')}
                value={'checkIn'}
              />
            }
            label="Check-In"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.auctionOrRaffle}
                onChange={onChange('auctionOrRaffle')}
                value={'auctionOrRaffle'}
              />
            }
            label="Auction/Raffle"
          />
        </FormGroup>
        <FormGroup className={classes.radioColumn}>
          <FormControlLabel
            control={
              <Checkbox
                checked={values.textToDonate}
                onChange={onChange('textToDonate')}
                value={'textToDonate'}
              />
            }
            label="Text to Donate"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.sellItems}
                onChange={onChange('sellItems')}
                value={'sellItems'}
              />
            }
            label="Sell Items"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={values.mobileCheckout}
                onChange={onChange('mobileCheckout')}
                value={'mobileCheckout'}
              />
            }
            label="Mobile Checkout"
          />
        </FormGroup>
      </FormControl>
      <IconButton className={classes.iconButton} onClick={() => updateView(2)}>
        <FontAwesomeIcon className={classes.icon} icon={faArrowRight} />
      </IconButton>
    </div>
  )
}

export default withStyles(styles)(Step1View)
